import React from "react"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import styled from "styled-components";
import {Container} from "../styles/global"
import {graphql} from "gatsby"

function Terms({data, location}) {
    console.log(data)

    return (
        <Layout location={location}>
            <SEO title="Terms and Conditions"/>
            <Container>
                <PrivacyTextGroup>
                    <div dangerouslySetInnerHTML={{__html: data.allMarkdownRemark.edges[0].node.html}}/>
                </PrivacyTextGroup>

                {/*</PrivacyTextGroup>*/}

            </Container>
        </Layout>
    )
}


export default Terms

const PrivacyTextGroup = styled.div`

    padding: 160px 20px 10px 60px;
    position: relative;
    // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
    @media (max-width: ${props => props.theme.screen.md}) {
    }
    // margin: 0;
    // padding: 20px;

    h1 {
        margin: 0 0 24px;
        color: ${props => props.theme.color.navy};
    }

    h2 {
        margin-bottom: 24px;
        color: ${props => props.theme.color.navy};
    }

    p {
        color: ${props => props.theme.color.navy};
        margin-bottom: 24px;
    }
`

const Flex = styled.div`
    display: grid;
    justify-content: space-between;
    align-content: center;
    grid-template-columns: 1fr 1fr;
    @media (max-width: ${props => props.theme.screen.md}) {
        grid-template-columns: 1fr;
        grid-gap: 64px;
    }
`

export const query = graphql`
  query {
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/terms/terms.md$/"}}) {
    edges {
      node {
        html
      }
    }
  }
  }
`